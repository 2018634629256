import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/fede/source/largoplacismo/src/components/layout.js";
import { Link } from "gatsby";
import More from "../../components/more-toggle";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className='callout'>En la <Link to='/introduccion' mdxType="Link">introducción</Link> al largoplacismo que encontrarás en este
  sitio web se resumen algunas de las ideas asociadas a esta postura ética. En esta página hemos incluido varios recursos externos (libros, artículos, pódcast y vídeos) para profundizar en el tema. Los recursos que tienen una traducción o una aclaración entre paréntesis solo están disponibles en inglés.</div>
    <h2 className='centered-header'>Libros</h2>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://theprecipice.com/"
        }}><em parentName="a">{`The Precipice`}</em></a>{` (El precipicio) de Toby Ord (2020)`}</strong></p>
    <p>{`Esta obra pionera es un buen punto de partida. En ella se explora la base científica y filosófica que subyace a los riesgos a gran escala a los que nos enfrentamos. Además, pone estos riesgos en el contexto de la historia de la humanidad en su conjunto y muestra por qué librarnos de ellos es uno de los problemas más acuciantes de nuestro tiempo. También nos enseña el camino que deberíamos seguir, es decir, las acciones y estrategias que podemos llevar a cabo hoy para salvaguardar el futuro de la humanidad.`}</p>
    <p>{`En `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=CrMIEz_mSJM&t=290s"
      }}>{`esta presentación de 20 minutos`}</a>{`, narrada por el propio autor, se resumen las ideas que contiene el libro. Si prefieres los pódcast, `}<a parentName="p" {...{
        "href": "https://80000hours.org/podcast/episodes/why-the-long-run-future-matters-more-than-anything-else-and-what-we-should-do-about-it/"
      }}>{`te recomendamos esta entrevista`}</a>{` con Toby Ord, en la que se habla sobre por qué el futuro a largo plazo de la humanidad importa más que cualquier otra cosa y qué podemos hacer cada uno de nosotros.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://whatweowethefuture.com"
        }}><em parentName="a">{`What We Owe The Future`}</em></a>{` (Lo que debemos al futuro) de William MacAskill (2022). Publicación prevista en español para 2023.`}</strong></p>
    <p>{`Este libro es una exploración de los argumentos filosóficos y empíricos a favor del largoplacismo. En él se describen algunas de las formas en que podemos influir en el futuro a largo plazo y se plantean preguntas filosóficas como «¿está bien engendrar personas felices?» y «¿el futuro será bueno o malo?». También rastrea la historia de los cambios en nuestras creencias morales y sostiene que avances como la abolición de la esclavitud pueden haber dependido sorprendentemente de las acciones de pequeños grupos de personas.`}</p>
    <p>{`Hay muchos pódcast en los que se habla del libro. `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/2022/08/09/opinion/ezra-klein-podcast-will-macaskill.html"
      }}>{`La entrevista de Ezra Klein con William MacAskill en `}<em parentName="a">{`The New York Times`}</em>{` es un buen punto de partida`}</a>{`. En este tuit encontrarás cuatro de las mejores entrevistas con el autor.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://philpapers.org/rec/CARTLV-2"
        }}><em parentName="a">{`The Long View: Essays on Policy, Philanthropy, and the Long-term Future`}</em></a>{` (La visión a largo plazo: Ensayos sobre políticas, filantropía y el futuro lejano), de Natalie Cargill (autora y editora) y Tyler M. John (editor)`}</strong></p>
    <p>{`Este libro electrónico gratuito, que se ha descrito como «una guía para los filántropos, personas comprometidas y legisladores que quieran hacer el mayor bien posible», es una recopilación de ensayos «de los pioneros que han sentado las bases intelectuales del largoplacismo, así como de expertos y defensores de la causa que están poniéndolo en práctica». El libro contiene propuestas de políticas planteadas por filósofos políticos y miembros de la Cámara de los Lores y del grupo parlamentario en defensa de las generaciones futuras (conformado por miembros de distintos partidos políticos del Reino Unido); argumentos de expertos destacados a favor del trabajo en bioseguridad, inteligencia artificial (IA) y cambio climático; y el primer ensayo que se ha escrito acerca del cambio cultural largoplacista, obra del antiguo jefe del Gabinete del presidente de Finlandia.`}</p>
    <h2 className='centered-header'>Otros escritos</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.bbc.com/future/article/20220805-what-is-longtermism-and-why-does-it-matter"
          }}><em parentName="a">{`What is longtermism?`}</em></a>{` (¿Qué es el largoplacismo?) de William MacAskill. Este breve artículo, publicado por la BBC, resume el argumento a favor del largoplacismo y presenta algunos ejemplos de organizaciones y proyectos relevantes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`La `}<a parentName="p" {...{
            "href": "https://www.cold-takes.com/most-important-century/"
          }}>{`serie de entradas de blog sobre «el siglo más importante»`}</a>{` (en inglés) de Holden Karnofsky. En esta serie de entradas de blog, el autor sostiene que «el siglo XXI podría ser el más importante de la humanidad, a consecuencia del desarrollo de sistemas de IA avanzados que puedan acelerar drásticamente los avances científicos y tecnológicos, lo que nos acercaría más rápido de lo que muchas personas imaginan a un futuro profundamente insólito». Si solo tienes tiempo de leer una cosa, este es un buen punto de partida.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`También puedes escuchar esta serie de entradas de blog en `}<a parentName="li" {...{
              "href": "https://open.spotify.com/show/3CocRoFqE6UI4EmyfMevUj"
            }}>{`Spotify`}</a>{` or `}<a parentName="li" {...{
              "href": "https://podcasts.apple.com/podcast/id1586669292"
            }}>{`Apple Podcasts`}</a>{`. `}<a parentName="li" {...{
              "href": "https://ourworldindata.org/longtermism"
            }}><strong parentName="a">{`The Future is Vast: Longtermism’s perspective on humanity’s past, present, and future`}</strong></a>{` (El futuro es inmenso: la perspectiva del largoplacismo acerca del pasado, el presente y el futuro de la humanidad). En este ensayo de Max Roser, fundador de Our World in Data, se emplean infografías para transmitir la idea de que «si conseguimos evitar una gran catástrofe, estaremos viviendo en los albores de la historia de la humanidad».`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://80000hours.org/articles/future-generations/"
          }}><strong parentName="a">{`Why our impact in millions of years could be what most matters`}</strong></a>{` (Por qué nuestro impacto dentro de millones de años podría ser lo más importante). Este artículo del `}<a parentName="p" {...{
            "href": "https://80000hours.org/"
          }}>{`sitio web de 80,000 Hours`}</a>{` trata sobre la magnitud esperada del futuro y sobre nuestras obligaciones morales con las generaciones futuras. La sección `}<a parentName="p" {...{
            "href": "https://80000hours.org/articles/future-generations/#longtermism-in-a-nutshell"
          }}><em parentName="a">{`Longtermism in a nutshell`}</em></a>{` (El largoplacismo en pocas palabras) es particularmente útil.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.nytimes.com/2022/08/05/opinion/the-case-for-longtermism.html"
          }}><strong parentName="a">{`The Case for Longtermism`}</strong></a>{` (En defensa del largoplacismo) de William MacAskill. En este artículo de `}<em parentName="p">{`The New York Times`}</em>{` se exponen los argumentos filosóficos a favor del largoplacismo. Comienza con un experimento mental: «Imagina vivir la vida de cada ser humano que ha existido en la historia, empezando por el primero en nacer».
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.effectivealtruism.org/articles/longtermism/"
          }}><strong parentName="a">{`Introduction to Longtermism`}</strong></a>{` (Introducción al largoplacismo). En este artículo, publicado en `}<a parentName="p" {...{
            "href": "https://www.effectivealtruism.org/"
          }}>{`effectivealtruism.org`}</a>{`, se presenta un resumen algo más extenso del largoplacismo y se abordan varias definiciones y objeciones.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.effectivealtruism.org/articles/cause-profile-long-run-future/"
          }}><strong parentName="a">{`The Long-Term Future`}</strong></a>{` (El futuro a largo plazo). La investigadora en IA `}<a parentName="p" {...{
            "href": "https://jesswhittlestone.com/"
          }}>{`Jess Whittlestone`}</a>{` sostiene en este artículo que el futuro a largo plazo debería ser uno de los objetivos del altruismo.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`En la `}<a parentName="p" {...{
            "href": "https://es.wikipedia.org/wiki/Largoplacismo"
          }}>{`página de Wikipedia`}</a>{` sobre el largoplacismo se resume su historia y las críticas que ha recibido, y se incluyen muchas referencias.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.forethought.org/longtermism"
          }}><strong parentName="a">{`Longtermism: Potential Research Projects`}</strong></a>{` (Largoplacismo: proyectos de investigación potenciales). Es la página web donde se presentan el trasfondo del largoplacismo y los posibles proyectos de investigación de la Forethought Foundation for Global Priorities Research (Fundación Forethought para la investigación sobre prioridades globales).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://1000wordphilosophy.com/2021/09/17/longtermism/"
          }}><strong parentName="a">{`How much should we care about the far future?`}</strong></a>{` (¿Cuánto deberíamos preocuparnos por el futuro lejano?). Este ensayo de `}<em parentName="p">{`1000-Word Philosophy`}</em>{` aborda algunas objeciones que se han hecho al largoplacismo.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://press.stripe.com/stubborn-attachments"
          }}><strong parentName="a">{`Stubborn Attachments`}</strong></a>{` (Vínculos tenaces) de Tyler Cowen. Este libro corto ofrece argumentos a favor de una de las muchas conclusiones que podrían surgir tras pensar en escalas de tiempo más extensas, a saber: que deberíamos «centrar nuestra atención en esfuerzos a largo plazo que aumenten al máximo el crecimiento económico sostenible y respeten los derechos humanos».
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.goodreads.com/book/show/25979828-mapas-del-tiempo"
          }}><strong parentName="a">{`Mapas del tiempo: Introducción a la gran historia`}</strong></a>{` de David Christian. La influyente introducción a la «gran historia», en la que se estudian temas y tendencias de toda la historia de la humanidad y mucho más.`}</p>
      </li>
    </ul>
    <More title="¿Quieres saber más? Selecciona este recuadro para ver más escritos sobre el pensamiento a largo plazo." mdxType="More">
      <p>{`El pensamiento a largo plazo implica imaginar proyectos y procesos que podrían perdurar durante miles de años, es decir, pensar en la escala temporal de la civilización. Esta corriente es muy cercana al largoplacismo y también una gran fuente de inspiración.`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://www.goodreads.com/book/show/61199590-el-buen-antepasado"
            }}><strong parentName="a">{`*El buen antepasado**`}</strong></a>{` de Roman Krznaric`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://www.goodreads.com/book/show/33279.Clock_of_the_Long_Now"
            }}><strong parentName="a"><em parentName="strong">{`Clock of the Long Now`}</em></strong></a>{` (El reloj del largo ahora) de Stewart Brand`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://longviewer.substack.com/"
            }}><strong parentName="a"><em parentName="strong">{`The Long View: A Field Guide`}</em></strong></a>{` (La guía de campo del largoplacista) de Richard Fisher`}</p>
        </li>
      </ul>
    </More>
    <h2 className='centered-header'>Charlas y pódcast</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.nytimes.com/2022/08/09/opinion/ezra-klein-podcast-will-macaskill.html"
          }}>{`Ezra Klein (periodista de `}<em parentName="a">{`The New York Times`}</em>{`) entrevista a Will MacAskill`}</a>{` (en inglés). Una introducción al largoplacismo en formato audio. Klein inicia la conversación con estas tres frases: «Las personas que vivan en el futuro importan. Podría haber muchas de ellas. Podemos hacer que sus vidas sean mejores».`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://80000hours.org/podcast/"
          }}>{`El pódcast de 80,000 Hours`}</a>{` (en inglés). Además de su entrevista con Toby Ord, el pódcast de 80,000 Hours tiene más de 100 conversaciones reflexivas y en profundidad acerca de los problemas más acuciantes del mundo y qué podemos hacer para resolverlos. Muchas de ellas se centran en aspectos del largoplacismo. En particular, recomendamos los siguientes:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://80000hours.org/podcast/episodes/ajeya-cotra-worldview-diversification/"
              }}>{`Conversación con Ajeya Cotra sobre la diversificación de las visiones del mundo y sobre el tamaño que podría tener el futuro`}</a>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://80000hours.org/podcast/episodes/ben-todd-on-varieties-of-longtermism/"
              }}>{`Conversación con Benjamin Todd sobre distintos tipos de largoplacismo`}</a>{`. En la primera mitad del episodio se habla de una manera de distinguir los distintos enfoques del largoplacismo.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://80000hours.org/podcast/episodes/luisa-rodriguez-why-global-catastrophes-seem-unlikely-to-kill-us-all/"
              }}>{`Conversación con Luisa Rodriguez sobre por qué parece poco probable que las catástrofes globales acaben con toda la humanidad`}</a>{`.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Aparte de los episodios del pódcast de 80,000 Hours, quizá te interesen estos otros (también en inglés):`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "http://rationallyspeakingpodcast.org/261-dangerous-biological-research-is-it-worth-it-kevin-esvelt/"
              }}>{`Dangerous biological research -- is it worth it? (Investigaciones biológicas peligrosas: ¿merecen la pena?)`}</a>{` `}<a parentName="p" {...{
                "href": "http://rationallyspeakingpodcast.org/"
              }}>{`(pódcast de `}<em parentName="a">{`Rationally Speaking`}</em>{`, presentado por Julia Galef)`}</a></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://futureoflife.org/2018/09/27/podcast-ai-and-nuclear-weapons-trust-accidents-and-new-risks-with-paul-scharre-and-mike-horowitz/"
              }}>{`Conversación con Paul Scharre y Mike Horowitz sobre la IA y las armas nucleares`}</a>{` `}<a parentName="p" {...{
                "href": "https://futureoflife.org/the-future-of-life-podcast/"
              }}>{`(pódcast del Future of Life Institute)`}</a></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://hearthisidea.com/episodes/thomas"
              }}>{`Thomas Moynihan on the History of Existential Risk (Conversación con Thomas Moynihan sobre la historia de los riesgos existenciales)`}</a>{` `}<a parentName="p" {...{
                "href": "https://hearthisidea.com/"
              }}>{`(pódcast `}<em parentName="a">{`Hear This Idea`}</em>{`)`}</a></p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=Ccq2Ql8FcY0"
          }}><em parentName="a">{`Orienting towards the long-term future`}</em></a>{` (Orientarnos hacia el futuro a largo plazo). En este vídeo de 25 minutos, el investigador Joseph Carlsmith habla sobre la importancia de mejorar el futuro a largo plazo, una idea que motiva a algunas personas de la comunidad del altruismo eficaz.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=vCpFsvYI-7Y"
          }}><em parentName="a">{`WHat We Owe The Future`}</em></a>{` (Lo que debemos al futuro). En esta charla de 40 minutos, el filósofo de Oxford William MacAskill explica por qué la historia de la humanidad hasta el día de hoy pone de relieve la importancia de influir positivamente en el futuro a largo plazo y por qué el momento actual podría tener una repercusión inusual a este respecto. MacAskill ha publicado en el 2022 un libro homónimo acerca de este tema.`}</p>
      </li>
    </ul>
    <h2 className='centered-header'>Filosofía</h2>
    <p>{`Los artículos enumerados abajo abordan los aspectos filosóficos del largoplacismo en mayor detalle. Son un poco más técnicos que los recursos mencionados arriba.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://globalprioritiesinstitute.org/hilary-greaves-william-macaskill-the-case-for-strong-longtermism-2/"
          }}><em parentName="a">{`The case for strong longtermism`}</em></a>{` (En defensa del largoplacismo radical). Hilary Greaves y William MacAskill analizan las versiones radicales del largoplacismo y los motivos para adoptarlas.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://globalprioritiesinstitute.org/christian-tarsney-the-epistemic-challenge-to-longtermism/"
          }}><em parentName="a">{`The epistemic challenge to longtermism`}</em></a>{` (El desafío epistémico del largoplacismo). Christian Tarsney trata una importante objeción al largoplacismo, esto es: que los efectos de nuestras acciones en el futuro a largo plazo podrían ser prácticamente imposibles de predecir.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://globalprioritiesinstitute.org/tyler-m-john-and-william-macaskill-longtermist-institutional-reform/"
          }}><em parentName="a">{`Longtermist institutional reform`}</em></a>{` (Una reforma institucional largoplacista). Tyler M. John y William MacAskill investigan las razones por las que las generaciones futuras están desatendidas en el ámbito político, y plantean varias vías por las que las instituciones políticas podrían empezar a defender los intereses de las personas futuras.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://forum.effectivealtruism.org/posts/NYxaxLZS5tiiyiHnG/a-full-syllabus-on-longtermism?fbclid=IwAR3rpu3yUoM9msYb7XktFk_c1Exx87ZVHR1gOCPE18z27rfC7hkqvaJy4ms"
          }}>{`Un plan de estudios completo sobre el largoplacismo`}</a>{` (en inglés). Se trata de una lista exhaustiva de escritos acerca de la filosofía del largoplacismo. Puedes consultar `}<a parentName="p" {...{
            "href": "https://www.stafforini.com/blog/courses-on-longtermism/"
          }}>{`más cursos sobre el largoplacismo`}</a>{` y `}<a parentName="p" {...{
            "href": "https://theprecipice.com/syllabus"
          }}>{`este plan de estudios sobre los riesgos existenciales`}</a>{` basado en el libro `}<a parentName="p" {...{
            "href": "https://theprecipice.com/"
          }}><em parentName="a">{`The Precipice`}</em></a>{` (El precipicio).`}</p>
      </li>
    </ul>
    <h2 className='centered-header'>Historia</h2>
    <p>{`Muchas de las ideas centrales del largoplacismo contemporáneo hunden sus raíces en las obras de pensadores y defensores de esta causa que nos precedieron. A continuación ofrecemos una pequeña selección de escritos anteriores relacionados con el largoplacismo actual.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Frank Ramsey, `}<a parentName="p" {...{
            "href": "https://www.jstor.org/stable/2224098"
          }}><em parentName="a">{`Una teoría matemática del ahorro`}</em></a>{` (1928)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Winston Churchill, `}<a parentName="p" {...{
            "href": "https://www.nationalchurchillmuseum.org/fifty-years-hence.html"
          }}><em parentName="a">{`Fifty Years Hence`}</em></a>{` (De aquí a cincuenta años, 1931)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`El `}<a parentName="p" {...{
            "href": "https://www.atomicheritage.org/key-documents/russell-einstein-manifesto"
          }}><em parentName="a">{`Manifiesto Russell-Einstein`}</em></a>{` (1955)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`John von Neumann, `}<a parentName="p" {...{
            "href": "http://geosci.uchicago.edu/~kite/doc/von_Neumann_1955.pdf"
          }}><em parentName="a">{`Can We Survive Technology?`}</em></a>{` (¿Podremos sobrevivir a la tecnología?, 1955)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Jonathan Schell, `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/The_Fate_of_the_Earth"
          }}><em parentName="a">{`El destino de la tierra`}</em></a>{` (1982)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Derek Parfit, `}<a parentName="p" {...{
            "href": "https://es.wikipedia.org/wiki/Razones_y_personas"
          }}><em parentName="a">{`Razones y personas`}</em></a>{` (1984)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Annette Baier, `}<a parentName="p" {...{
            "href": "https://academic.oup.com/book/5041/chapter-abstract/147554720?redirectedFrom=fulltext"
          }}><em parentName="a">{`The Rights of Past and Future Persons`}</em></a>{` (Los derechos de las personas pasadas y futuras, 1981)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Carl Sagan, `}<a parentName="p" {...{
            "href": "https://www.goodreads.com/book/show/947856.Un_punto_azul_p_lido"
          }}><em parentName="a">{`Un punto azul pálido`}</em></a>{` (1994)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.unesco.org/es/legal-affairs/declaration-responsibilities-present-generations-towards-future-generations"
          }}><em parentName="a">{`Declaración sobre las Responsabilidades de las Generaciones Actuales para con las Generaciones Futuras`}</em></a>{` de la Unesco (1997)`}</p>
      </li>
    </ul>
    <p>{`Si quieres explorar la historia del pensamiento acerca de la extinción humana, echa un vistazo al siguiente libro:`}</p>
    <ul>
      <li parentName="ul">{`Thomas Moynihan, `}<a parentName="li" {...{
          "href": "https://mitpress.mit.edu/9781913029845/"
        }}><em parentName="a">{`X-Risk:`}</em>{` `}<em parentName="a">{`How Humanity Discovered Its Own Extinction`}</em></a>{` (Riesgo X: Cómo descubrió la humanidad su propia extinción, 2020)`}</li>
    </ul>
    <p>{`Puedes encontrar una selección de citas históricas (en inglés) sobre el riesgo existencial en la página `}<a parentName="p" {...{
        "href": "https://theprecipice.com/quotations"
      }}>{`theprecipice.com/quotations`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      